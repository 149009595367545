@import '../../mixins';

.service-card {
  position: relative;
  
  padding: rem(30);
  width: rem(526);
  height: rem(356);
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: rem(20);
  
  color: var(--text-light-primary);
  border-radius: var(--radius-common);
  
  overflow: hidden;
  
  @include mediaBigDesktop {
    padding: big(30);
    width: big(740);
    height: big(550);
    gap: big(20);
  }
  
  @include mediaLaptop {
    padding: rem(30);
    width: rem(400);
    height: rem(270);
  }
  
  @include mediaTablet {
    padding: rem(20) rem(25) rem(25) rem(25);
    width: rem(354);
    height: rem(240);
    flex-direction: column;
    align-items: start;
  }
  
  @include mediaMobile {
    padding: rem(10) rem(15) rem(15) rem(15);
    width: rem(239);
    height: rem(240);
  }
  
  @include hover {
    & .service-card {
      &__image {
        & img {
          transform: scale(1.1);
        }
      }
    }
  }
  
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    pointer-events: none;
    user-select: none;
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      width: 100%;
      height: 100%;

      background-image: linear-gradient(0deg, var(--text-dark-secondary) 0%, rgba(0, 0, 0, 0.00) 45.14%);
    }
    
    & img,
    & picture {
      position: relative;
      z-index: 1;

      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
    }
  }
  
  &__content {
    position: relative;
    z-index: 2;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    
    text-decoration: none;
    
    @include mediaTablet {
      justify-content: start;
    }
  }
  
  & .button_icon {
    position: static;
    z-index: 2;
    
    flex-shrink: 0;
    
    &::before {
      @include pseudo(0)
    }
    
    @include mediaTablet {
      padding: rem(6);
    }
  }
}