.service-card {
  position: relative;
  padding: 1.875rem;
  width: 32.875rem;
  height: 22.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1.25rem;
  color: var(--text-light-primary);
  border-radius: var(--radius-common);
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .service-card {
      padding: 1.17188vw;
      width: 28.90625vw;
      height: 21.48438vw;
      gap: 0.78125vw; } }
  @media screen and (max-width: 109.375rem) {
    .service-card {
      padding: 1.875rem;
      width: 25rem;
      height: 16.875rem; } }
  @media screen and (max-width: 87.5rem) {
    .service-card {
      padding: 1.25rem 1.5625rem 1.5625rem 1.5625rem;
      width: 22.125rem;
      height: 15rem;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start; } }
  @media screen and (max-width: 39.375rem) {
    .service-card {
      padding: 0.625rem 0.9375rem 0.9375rem 0.9375rem;
      width: 14.9375rem;
      height: 15rem; } }
  @media (any-hover: hover) {
    .service-card:hover .service-card__image img {
      -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
              transform: scale(1.1); } }
  .service-card__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .service-card__image::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-image: -webkit-gradient(linear, left bottom, left top, from(var(--text-dark-secondary)), color-stop(45.14%, rgba(0, 0, 0, 0)));
      background-image: -o-linear-gradient(bottom, var(--text-dark-secondary) 0%, rgba(0, 0, 0, 0) 45.14%);
      background-image: linear-gradient(0deg, var(--text-dark-secondary) 0%, rgba(0, 0, 0, 0) 45.14%); }
    .service-card__image img,
    .service-card__image picture {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
      -webkit-transition: -webkit-transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: -webkit-transform var(--animation-timing-medium) var(--cubic-bezier);
      -o-transition: transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: transform var(--animation-timing-medium) var(--cubic-bezier), -webkit-transform var(--animation-timing-medium) var(--cubic-bezier); }
  .service-card__content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-decoration: none; }
    @media screen and (max-width: 87.5rem) {
      .service-card__content {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: start; } }
  .service-card .button_icon {
    position: static;
    z-index: 2;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .service-card .button_icon::before {
      content: "";
      position: absolute;
      top: -0rem;
      left: -0rem;
      width: calc(100% + 0rem * 2);
      height: calc(100% + 0rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .service-card .button_icon::before {
          top: 0vw;
          left: 0vw;
          width: calc(100% + 0vw * 2);
          height: calc(100% + 0vw * 2); } }
    @media screen and (max-width: 87.5rem) {
      .service-card .button_icon {
        padding: 0.375rem; } }
